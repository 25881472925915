import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../../globals';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public globals: Globals,
    private httpClient: HttpClient,
    private router: Router,
  ) {}

  private isAdministrator = false;
  private isAuth = false;

  checkUser() {
    this.httpClient.get(this.globals.getApiUrl() + '/api/user')
      .subscribe({
        next : (msg:any) => {
          if (msg._id === undefined) {
            this.logout();
          }
          this.login(msg);
          return true;
        },
        error : (e) => {
          this.logout();
          return false;
        }
      });
  }

  getCsrfCookie() {
    return this.httpClient.get(this.globals.getApiUrl() + '/sanctum/csrf-cookie');
  }

  isAdmin(): boolean {
    return this.isAdministrator;
  }

  isAuthenticated(): any {
    return this.isAuth;
  }

  login(user: any) {
    if (user.token !== undefined) {
      sessionStorage.setItem('token', user.token);
    }
    this.isAdministrator = user.isAdmin;
    this.isAuth = true;
    let redirectUrl = sessionStorage.getItem('redirectUrl');
    this.router.navigateByUrl(redirectUrl && user.token === undefined ? redirectUrl : '/home');
  }

  logout() {
    sessionStorage.removeItem('token');
    this.isAuth = false;
  }

  sendResetPasswordLink(email:any) {
    let url = this.globals.getApiUrl() + '/api/auth/password-forgotten';
    return this.httpClient.post(url, {email: email});
  }

  setNewPassword(password:any, token: any) {
    let url = this.globals.getApiUrl() + '/api/auth/password-reset';
    return this.httpClient.post(url, {password: password, token: token});
  }

  signIn(email:any, password:any) {
    let url = this.globals.getApiUrl() + '/api/auth/login';
    return this.httpClient.post(url, {email: email, password: password});
  }
}
